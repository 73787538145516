.admin-home-container{
    width: 100%;
    height: 100%;
}

.admin-home-container .upper{
    display: flex;
    width: 100%;
    height: 50%;
}

.admin-home-container .left{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.admin-home-container .right{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.admin-home-container .lower{
    display: flex;
    width: 100%;
    height: 50%;
}
