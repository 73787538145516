/*
*******************************************
_______ALERT_BOX CSS START_______
*******************************************
*/

/* css for alert box in admin panel */
.admin-panel-alert-box-container {
  position: fixed;
  z-index: 100;
  top: 50%;
  width: 400px;
  height: 170px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid black;
}

/* css for alert box not displayed inside admin panel */
.non-admin-panel-alert-box-container {
  position: absolute;
  z-index: 100;
  top: 40%;
  width: 400px;
  height: 170px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
}


.alert-box-container__message {
  width: 100%;
  text-align: center;
  color: black;
}

.alert-box-container__message h3 {
  padding: 0px 30px;
  font-weight: bolder;
}

.alert-box-container__close--button {
  text-align: right;
  background: #21AEA7;
}

.alert-box-container__close--button button {
  text-align: center;
  margin-right: 10px;
  font-size: 1.5em;
  border: none;
  background: #21AEA7;

}


/*
*******************************************
_______ALERT_BOX  CSS END_______
*******************************************
*/

/* Studentsay section testimonial section */
.arrow-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.testimonial-text {
  font-size: 16px;
  margin: 20px 0;
}

.rounded-image {
  border-radius: 50%;
  max-width: 100%;
  height: 150px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  /* Adjust styles for screens up to 768px wide */

  .essonova-btn {
    font-size: 18px; /* Adjust button text size */
  }

  .testimonial-text {
    font-size: 14px; /* Adjust testimonial text size */
    margin: 10px 0; /* Adjust margins as needed */
  }
}

@media (max-width: 480px) {
  /* Adjust styles for screens up to 480px wide */

  .essonova-btn {
    font-size: 16px; /* Adjust button text size */
  }

  .rounded-image {
    max-width: 80%; /* Adjust image size */
  }

  .testimonial-text {
    font-size: 12px; /* Adjust testimonial text size */
    margin: 5px 0; /* Adjust margins as needed */
  }
}

/*________________________________________________________________________________________________________________*/

/*
*******************************************
_______Quote  CSS Start_______
*******************************************
*/

.quote-body {
  background: linear-gradient(120deg, rgb(206, 237, 235) 50%, #21AEA7 50%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
}

.quote-image img {
  max-width: 100%;
  height: 70vh;
  border-radius: 50%;
  margin: 0 auto;
}

.quote-text {
  margin-top: 80px;
  font-size: 36px;
  color: white;
  padding: 10px;
  border-radius: 10px;
}

.quote-icon {
  font-size: 24px;
  color: rgb(206, 237, 235);
  margin: 0 5px;
}

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
  .quote-text {
    font-size: 24px; /* Adjust the font size for smaller screens */
    padding: 2px;
  }
  .quote-icon {
    font-size: 18px; /* Adjust the icon size for smaller screens */
  }
}
@media (max-width: 767px) {
  .quote-image img {
    height: 40%; /* Allow the image height to adjust as needed on smaller screens */
  }
  @media (max-width: 480px) {
    .quote-image img {
      max-height: 30vh; /* Further adjust image height for even smaller screens */
    }
  }
}

/*
*******************************************
_______Quote  CSS END_______
*******************************************
*/
/*
*******************************************
_______BANNER CSS START_______
*******************************************
*/

.hero-section {
  position: relative;
  /* background: #59bad5; */
  overflow: hidden;
}

/* Styles for the hero content */
.hero-content {
  margin-top: 10px;
  /* padding: 50px; */

}

/* Styles for the career button */
.career-button {
  background-color: #21AEA7;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  margin: 0 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.career-button:hover {
  background-color: #3F1179;
}


/* Styling for the animated headings */
.animated-heading {
  font-size: 36px;
  margin: 0;
  opacity: 0;
  transform: translateY(50px);
  animation: slideIn 1s forwards;
}

.animated-subheading {
  font-size: 17px;
  margin: 10px 0;
  padding: 10px;
  opacity: 0;
  color: #3F1179;
  animation: slideIn 1s forwards 0.5s;
}

.animated-subsubheading {
  font-size: 20px;
  margin: 10px 0;
  padding: 10px;
  opacity: 0;
  transform: translateY(50px);
  animation: slideIn 1s forwards 0.5s;
}
 .animated-image {
  border-radius: 10px;
  width: 100%;
  height: 80%;
  opacity: 0;
  transform: translateY(50px);
  animation: slideIn 1s forwards 1s;
}

.image {
  text-align: center;
  max-width: 100%;
  height:70vh;
}



/* Animation keyframes */
@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@media screen and (min-width: 765px) and (max-width: 995px) {
  .banner-button-container {
    display: inline;
  }
}

@media screen and (min-width: 255px) and (max-width: 455px) {
  .hero-content {
    padding: 10px;
  }
}

/*
*******************************************
_______BANNER CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______Partner CSS START________
*******************************************
*/


/* src/components/PartnerSection.css */
#partner-section {
  padding: 10px;
}

#partner-section {
  text-align: center;
  font-size: 36px;

  color: #333;
}

.container-partner {
  margin: 10px 20px 0px 20px;
}

.partner-item {
  text-align: center;
  /* margin-bottom: 30px; */
  transition: transform 0.3s ease-in-out;
}

.partner-item:hover {
  transform: scale(1.05);
}

.partner-logo img {
  max-width: 100%;
  height: auto;
  transition: opacity 0.3s ease-in-out;
}

.partner-item:hover .partner-logo img {
  opacity: 0.8;
}

.Course_Name {
  /* border: 2px solid red; */
  width: 1200px;
  height: 200px;

}

.headingCourse1 {
  /* border: 2px solid green; */
  width: 50%;
  height: 20%;
  text-align: center;
  margin-left: 25%;
}

.listNaming {
  /* border: 2px solid black; */
  width: 50%;
  height: 70%;
}


/*
*******************************************
_______Partner CSS End________
*******************************************
*/


/*
*******************************************
_______BENEFITS CSS START________
*******************************************
*/

/* Style the container and section */
.services-block {
  margin: 10px 100px;
  border-radius: 4px;
  /* background-color: #C4E1D7; */
  padding: 40px 0;
  transition: background-color 0.3s ease;
  /* Add a smooth background color transition */
}

/* Hover effect for the section background */
/* Style the title and subtitle */
.title-holder {
  text-align: center;
  margin-bottom: 20px;
  transform: translateY(0);
  transition: transform 0.3s ease;
  /* Add a smooth title transform transition */
}

.title-holder:hover {
  transform: translateY(-5px);
  /* Add a subtle upward lift on hover */
}

.title-holder h2 {
  font-size: 36px;
  color: #333;
  transition: color 0.3s ease;
  /* Add a smooth text color transition */
}

/* Style the subtitle */
.subtitle {
  text-align: center;
  font-size: 18px;
  color: #0f0e0e;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  /* Add a smooth opacity transition */
}

.subtitle:hover {
  opacity: 1;
  /* Increase opacity on hover */
}

/* Style the service items */
.holder {
  text-align: center;
  padding: 20px;
  transform: scale(1);
  transition: transform 0.3s ease;
  /* Add a smooth scale transform transition */
}

.holder:hover {
  transform: scale(1.05);
  /* Slightly enlarge on hover */
}

.icon {
  font-size: 36px;
  color: #21AEA7;
  margin-bottom: 10px;
  transition: color 0.3s ease;
  /* Add a smooth icon color transition */
}

/* Hover effect for the icon color */
.icon:hover {
  color: #3F1179;
  /* Change color on hover */
}

h2 {
  padding: 2px;
}

h5 {
  font-size: 24px;
  color: #3F1179;
}

p {
  font-size: 16px;
  color: #666;
}

@media screen and (max-width:450px) {
  .services-block{
    margin: 10px 10px;
  }
}


/*
*******************************************
_______BENEFITS CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/

/*
*******************************************
_______INTRODUCTION CSS START________
*******************************************
*/
/* Default styles that apply to all screen sizes */
.text-content {
  padding: 20px;
}

.video-column {
  order: 2;
}

.text-column {
  order: 1;
}

/* Apply different styles for small screens (e.g., mobile devices) */
@media (max-width: 767px) {

  .video-column,
  .text-column {
    width: 100%;
  }

  .video-iframe {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
}

/* Style the "Get Started" button */
.get-started-btn {
  display: inline-block;
  background-color: #55C0BB;
  color: #fff;
  margin-left: 20px;
  padding: 10px 20px;
  background: #21AEA7;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.get-started-btn:hover {
  background: #3F1179;
  color: white;
}


/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .video-iframe {
    max-width: 100%;
    height: auto;
  }
}



/*
*******************************************
_______INTRODUCTION CSS END________
*******************************************
*/
/*
*******************************************
_______Essonova BANNER CSS START_______
*******************************************
*/

.essonova-banner {
  background: rgb(206, 237, 235);
  padding: 20px;
  text-align: center;
}
.essonova-banner h3{
  color: #3F1179;
  font-size: 28px;
}

.banner-list {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0 auto ;
  max-width: 900px;
}

.banner-item {
  text-align: center;
  padding: 20px;
  width: 400px;
  transition: transform 0.3s;
}

.icon {
  font-size: 40px;
  color: #21AEA7;
  transition: color 0.3s;
}

.icon:hover {
  color: #3F1179;
}

/* Recommended Media Query for All Screen Sizes */
@media screen and (max-width: 767px) {
  .banner-list {
    flex-direction: column;
  }

  .banner-item {
    margin: 10px 0;
  }
}

@media screen and (max-width: 767px) {
  .banner-list {
    padding: 0;
  }
}




/*
*******************************************
_______Essonoa BANNER CSS END______________
*******************************************
*/


/*
*******************************************
_______TESTIMONIAL CSS START_______
*******************************************
*/

/* Testimonials container */
.testimonial-container {
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 20px; */
}

/* Testimonials card */
.testimonial-card {
  padding: 20px;
  border-radius: 10px;
  /* Round the card */
  transition: transform 0.3s ease-in-out;
}

.testimonial-card:hover {
  transform: scale(1.02);
  /* Add hover animation */
}

/* Testimonial image */
.testimonial-image {
  margin:10px auto;
  left: 0;
  right: 0;
  width: 250px;
 
  border-radius: 100%;
  height: 250px;
  /* Maintain aspect ratio */
  transition: transform 0.3s ease-in-out;
  /* Add transition for zoom effect */
}

.testimonial-card:hover .testimonial-image {
  transform: scale(0.95);
  /* Zoom out on hover */
}

/* Testimonial name and position */
.testimonial-name {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.testimonial-position {
  font-size: 16px;
  color: #555;
}

/* Testimonial description */
.testimonial-description {
  font-size: 16px;
  margin-top: 15px;
}

/* Navigation buttons */
.btn-primary {
  background-color: #21AEA7;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.btn-primary:hover {
  background-color: #3F1179;
  /* Darker shade on hover */
}

/* Center align elements */
.d-flex {
  justify-content: center;
  align-items: center;
}


/*
*******************************************
_______TESTIMONIAL CSS END_______
*******************************************
*/

/*__________________________________________________________________________________________________________________*/

/*
*******************************************
_______Instructor CSS Start________
*******************************************
*/

/* Custom CSS for instructor section */
/* Common styles for instructor cards */
.instructor-section-card-container {
  /* padding: 20px; */
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.instructor-section-card {
  flex: 0 0 calc(33.33% - 20px);
  border: 1px solid #21AEA7;
  border-radius: 10px;
  text-align: center;
  margin: 10px;
}

.instructor-section-card__image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.instructor-img-top {
  padding: 5px;
  border-radius: 100%;
  width: 150px;
  height: 150px;
}

.instructor-section-card__text {
  text-align: center;
  word-wrap: break-word;
  padding: 15px;
}

/* Style for instructor images */
.instructor-image {
  width: 150px;
  height: 150px;
}

/* Adjust image size for medium screens */
@media (max-width: 992px) {
  .instructor-section-card {
    flex: 0 0 calc(50% - 20px);
  }
}

/* Adjust image size for small screens */
@media (max-width: 768px) {
  .instructor-section-card {
    flex: 0 0 calc(100% - 20px);
  }
  .instructor-image {
    width: 120px;
    height: 120px;
  }
}

/* Adjust image size for extra small screens */
@media (max-width: 576px) {
  .instructor-image {
    width: 100px;
    height: 100px;
  }
}


/*
*******************************************
_______Instructor CSS END________
*******************************************
*/

/*
*******************************************
_______ISA CSS Start_______
*******************************************
*/
.isa-container {
  background: rgb(206, 237, 235);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
  text-align: center; /* Center the content horizontally */
  max-width: 1000px; /* Limit the maximum width to make it more attractive */
  margin: 0 auto; /* Center the container horizontally */
  margin-bottom: 10px;;
}

select {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  max-width: 250px;
  margin-top: 10px; /* Add space between the select box and content */
  width: 100%; /* Make the select box full width */
}


/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .isa-container {
    padding: 15px;
    max-width: 600px;
  }

  select {
    padding: 6px;
    font-size: 14px;
  }
}

/* Responsive styles for even smaller screens */
@media (max-width: 576px) {
  .isa-container {
    padding: 10px;
    max-width: 400px;
  }
  select {
    padding: 5px;
    font-size: 12px;
  }
}

/*
*******************************************
_______ISA CSS END_______
*******************************************
*/
/*
*******************************************
_______DROPDOWN CSS START________
*******************************************
*/
.dropdown {
  width: 100%;
  /* Set the width to 100% to make it responsive */
  color: #131212;
  position: absolute;
  top: 100%;
  /* left: 3%; */
  /* You can adjust the left position if needed */
  z-index: 1;
  /* navbar links class positioning is made relative for this absolute positioning */
  font-size: 20px;
  padding-top: 10px;
  border: 0px solid #fff;
  border-radius: 5px;
  /* Correct the border-radius value */
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}



.dropdown li {
  list-style: none;
  margin: 2% 2%;
}

.dropdown li:hover {
  transition: ease-in 0.3s;
  color: rgb(95, 196, 172);
  cursor: pointer;
  /* box-shadow: inset 360px 0 0 0 rgb(175, 228, 216), 0 0 10px 2px rgba(149, 225, 209, 0.7);
    color: rgb(11, 11, 15); */
}


.dropdown img {
  height: 36px;
  width: 50px;
  margin-right: 20px;
}

/* Media Query for screens up to 768px wide */

@media (max-width: 1050px) {
  .dropdown {
    right: 70%;
    top: 0%;
    width: 650px;
  }
  .dropdown li {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .dropdown {
    right: 70%;
    top: 0%;
    width: 350px;
  }

  .dropdown li {
    text-align: left;
  }

  .dropdown img {
    height: 30px;
    width: 50px;
    margin-right: 0px;
  }
}

/* Media Query for screens up to 500px wide */
@media screen and (max-width: 500px) {
  .dropdown {
    top: 10%;
    width: 280px;

    left: -55%;
    font-size: 16px;
  }

  .dropdown img {
    height: 25px;
    width: 45px;
  }
}


/*
*******************************************
_______DROPDOWN CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______FAQ CSS START________
*******************************************
*/

/* FAQ Section */
#faqs {
  padding: 40px 0 0;
}

.faq-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.section-title {
  text-align: center;
  margin-bottom: 30px;
}

.section-tag {
  font-size: 18px;
  color: #3F1179;
}

h3:hover {
  transition: color 0.3s ease;
  transform: translateY(-2px);
  /* Add a subtle upward lift on hover */
}

/* FAQ Items */
.faq-item {
  margin-bottom: 20px;
  /* background-color: #fff; */
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.faq-item .accordion-button {
  /* background-color: #C4E1D7; */
  /* color: #333; */
  font-weight: bold;
  border: none;
  /* border-bottom: 1px solid #b1d1da; */
  padding: 15px 20px;
  text-align: left;
  cursor: pointer;
  /* transition: background-color 0.3s, color 0.3s; */
  position: relative;
}

.faq-item .accordion-button .collapsed {
  /* background-color: #C4E1D7; */
  /* color: #3F1179; */
}

.faq-item .accordion-button:focus {
  outline: none;
}

/* Button Icon Styling */
.accordion-icon {
  position: absolute;
  top: 18px;
  right: 20px;
  transition: transform 0.3s;
}

/* Rotate the icon when the button is expanded */
.accordion-button.expanded .accordion-icon {
  transform: rotate(180deg);
}

.faq-item .accordion-body {
  padding: 20px;
  display: none;
  border-top: 4px solid #3F1179;
}

.faq-item.active .accordion-body {
  display: block;
  animation: fadeIn 0.5s ease-in-out;
}

/* Add animation for FAQ body */
@keyframes fadeIn {
  from {
    opacity: 0;
    max-height: 0;
  }

  to {
    opacity: 1;
    max-height: 1000px;
  }
}

/*
*******************************************
_______FAQ CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______NAVBAR CSS START________
*******************************************
*/

.neuratechlab__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 6rem;
  width: 100%;
}

.neuratechlab__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.neuratechlab__navbar-links_logo {
  margin-right: 2rem;
}

.neuratechlab__navbar-links_logo img {
  height: 150px;
  width: 180px;
  
}

.neuratechlab__navbar-links_container {
  display: flex;
  flex-direction: row;
  position: relative;
}


.neuratechlab__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.neuratechlab__navbar-links_container p,
.neuratechlab__navbar-sign p,
.neuratechlab__navbar-menu_container p {
  color: #3F1179;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.neuratechlab__navbar-sign button,
.neuratechlab__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #ffffff;
  background: #21AEA7;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.neuratechlab__navbar-sign button:hover,
.neuratechlab__navbar-menu_container button:hover {
  background-color: #3F1179;
  color: white;
}

.neuratechlab__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.neuratechlab__navbar-menu svg {
  cursor: pointer;
}

.neuratechlab__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: #ffffff;
  padding: 2rem;
  position: absolute;
  z-index: 99;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.neuratechlab__navbar-menu_container p {
  margin: 1rem 0;
}

.neuratechlab__navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 1050px) {
  .neuratechlab__navbar-links_container {
    display: none;
  }

  .neuratechlab__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .neuratechlab__navbar {
    padding: 2rem 4rem;
  }

  .neuratechlab__navbar-links_logo img {
    height: 80px;
    width: 80px;
  }
}

@media screen and (max-width: 550px) {
  .neuratechlab__navbar {
    padding: 2rem;
  }

  .neuratechlab__navbar-sign {
    display: none;
  }

  .neuratechlab__navbar-links_logo img {
    height: 80px;
    width: 80px;
  }

  .neuratechlab__navbar-menu_container {
    top: 20px;
  }

  .neuratechlab__navbar-menu_container-links-sign {
    display: block;
  }

  .neuratechlab__navbar-menu_container-links {
    width: 200px;
  }
}

/*
*******************************************
_______NAVBAR CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______PAGINATION CSS START________
*******************************************
*/


/*
*******************************************
_______PAGINATION CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______Instructor CSS START________
*******************************************
*/

.essonova-card {
  height: 400px;
  width: 350px;
  padding: 10px;
}

/*
*******************************************
_______Instructor  CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______PROFILE_ICON CSS START________
*******************************************
*/

.profile-icon-component {
  float: right;
  width: 220px;
  text-align: right;
  padding: 0.5rem 1rem;
  color: #3F1179;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  position: relative;
  cursor: pointer;
  display: flex;
  gap : 10px;
  align-items: center;
}

.profile-icon {
  border-radius: 100%;
  height: 50px;
  width: 50px;
} 


@media screen and (max-width: 450px) {
  .profile-icon-component {
    padding: 0;
    width: 30px;

  }
.profile-icon {
  
    height: 30px;
    width: 30px;
} 
  .profile-icon-component-text {
    display: none;
    align-items: end;
  }
}

/*
*******************************************
_______PROFILE ICON CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______PROFILE MENU CSS START________
*******************************************
*/

.profile-menu {
  padding-top: 10px;
  text-align: left;
  height: 140px;
  width: 190px;
  z-index: 10;
  position: absolute;
  top: 21%;
  right: 3%;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  color: #3F1179;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.profile-menu li {
  cursor: pointer;
  padding: 3px;
  list-style: none;
}

/*
*******************************************
_______PROFILE_MENU CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/

/*
*******************************************
_______ Program_Weekly_Schedule CSS START________
*******************************************
*/


/*
*******************************************
_______Program_Weekly_Schedule CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______ROADMAP CSS START________
*******************************************
*/
/* Roadmap Styles */
.roadmap {
  padding: 20px;
  background-color: #21AEA7; /* Updated background color */
  border-radius: 10px; /* Updated border-radius */
  overflow: hidden;
  max-width: 800px;
  margin: 0 auto;
}

/* Timeline Path Styles */
.timeline-path {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* overflow-x: auto; */
}

/* Step Styles */
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #21AEA7; /* Updated border */
  border-radius: 10px; /* Updated border-radius */
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-right: 10px;
  flex: 1;
  height: max-content;
}

/* Image Styles */
.step img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  padding-bottom: 10px;
}

/* Remove margin-right for the last step */
.step:last-child {
  margin-right: 0;
}

/* Active Step Styles */
.step.active {
  background: rgb(206, 237, 235);
  color: #140707;
}

/* Completed Step Styles */
.step.completed {
  background: rgb(206, 237, 235);
  color: #fff;
}

/* Path Arrow Styles */
.custom-arrow {
  text-align: center;
  font-size: 100%;
  color: #ccc;
  margin-top: -10px;
}

/* Completed Path Arrow Styles */
.custom-arrow.completed-arrow {
  color: #68b47a;
}

/* Centering Arrows */
.custom-arrow:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #3F1179;
  border-right: 2px solid #3F1179;
  transform: rotate(45deg);
  margin-right: 5px;
}

/* Centering Completed Arrows */
.custom-arrow.completed-arrow:before {
  border-top: 2px solid #59a26a;
  border-right: 2px solid #5ac773;
}

/* Change Path Arrow Direction for Small Screens */
@media (max-width: 576px) {
  .timeline-path {
    flex-direction: column;
    align-items: center;
  }

  .custom-arrow {
    margin: 10px 0;
    transform: rotate(90deg);
  }
}

/*
*******************************************
_______ROADMAP CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
**********************************************************************************************************************
**********************************************************************************************************************
**********************************************************************************************************************
**********************************************************************************************************************
_______COMPONENETS CSS END________

**********************************************************************************************************************
**********************************************************************************************************************
**********************************************************************************************************************
**********************************************************************************************************************
*/





/*
**********************************************************************************************************************
**********************************************************************************************************************
**********************************************************************************************************************
**********************************************************************************************************************
_______CONTAINER COMPONENTS CSS START________

**********************************************************************************************************************
**********************************************************************************************************************
**********************************************************************************************************************
**********************************************************************************************************************
*/

/*
*******************************************
_______ABOUT CSS START________
*******************************************
*/

/* Apply styles to the header section */
.page_heading {
  background: rgb(206, 237, 235);
  color: #fff;
  padding: 20px;
  text-align: center;
}

/* Apply styles to the main content */
.main-content {
  padding: 30px;
  text-align: justify;

}


/* Style section titles */
.section-title {
  font-weight: bold;
  margin-top: 20px;
}

/* Style founder and CEO sections */
.col-md-6 {
  margin-top: 20px;
}

/* Style the images and make them responsive */
img.rounded-circle {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  border: 5px solid rgb(206, 237, 235);
  border-radius: 50%;
}

/* Apply styles to the story section */
.nuratech-story {
  background-color: #f8f9fa;
  padding: 30px;
  text-align: justify;
}

/* Define a simple fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply the animation to specific elements */
.col-md-6,
.nuratech-story {
  animation: fadeIn 1s ease-in-out;
}

/* Media query for all screen sizes */
@media screen {
  /* Adjust the following properties as needed */
  
  /* Layout */
  body {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
  }
  
  /* Typography */
  h1 {
    font-size: 24px;
    line-height: 1.5;
  }
  
  p {
    font-size: 16px;
    margin: 10px 0;
  }
  
  
  /* Box Model */
  .box {
    margin: 10px;
    padding: 20px;
  }
  
  /* Borders */
  .bordered-element {
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* Transitions and Animations */
  .animated-element {
    transition: all 0.3s ease;
    transform: scale(1);
  }
}

/*
*******************************************
_______ABOUT CSS END________
*******************************************
*/

/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______BLOG CSS START________
*******************************************
*/

/* Style for the entire blogs container */
.blogs-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 5px;
}


/* Style the card */
.card {
    flex-grow: 1;
    height: auto; /* Change to auto if you want flexible height */
    padding: 15px;
    display: flex;
    flex-direction: column;
    border: 1px solid #21AEA7; /* Fixed the missing semicolon */
}

.card-img-top {
    object-fit: cover;
    width: 100%; /* Ensure the image width spans the entire container */
    height: 200px; /* Set a specific height for all card images */
}


/* Style the card title */
.card-title {
    font-size: 1.25rem; /* Adjust the font size as needed */
    margin-top: 10px;
}

/* Style the card text */
.card-text {
    font-size: 1rem; /* Adjust the font size as needed */
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp:4; /* Adjust the number of lines to display */
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
}


/* Style for the category selection button */
.btn-category {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    margin-top: 10px;
    border: 1px solid #ccc; /* Add the border property with a blue color value */
}
    .btn-category:hover {
        border: 1px solid #ccc; /* Add the border property with a blue color value */
    }
    .btn-category img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        border-radius: 2px;
        transition: transform 0.2s ease;
    }



    /* Style for search input */
.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.category-options {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 300px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.blogs-features {
    max-width: 1100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

/* Style for the "Read" button */
.essonova-btn {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

    .essonova-btn:hover {
        background-color: #0056b3;
    }

/* Media queries for responsiveness */
@media (max-width: 768px) {
    /* Adjust styles for smaller screens here */
    .btn-category {
        font-size: 14px;
    }

        .btn-category img {
            width: 16px;
            height: 16px;
        }

    .card-title {
        font-size: 16px;
    }
}



/*
*******************************************
_______BLOG CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/

/*
*******************************************
_______BLOG_ITEM CSS START________
*******************************************
*/
/*.blogitem-container {
  height: 800px;
  width: 70%;
  margin: auto;
}

.blogitem-container img {
  height: 400px;
  width: 80%;
}*/

/*
*******************************************
_______BLOG_ITEM CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/

/*
*******************************************
_______BLOG READ CSS________
*******************************************
*/

.blog-read-container{
  display: flex;
  max-width: 1100px;
  width: 100%;
  margin: 100px auto;
  margin-top: 10px;
}

.blog-read-container .blog-item{
  max-width: 700px;
  min-width: 450px;
  width: 65%;
  padding: 30px;
}
.blog-read-container .blog-item .blog-image {
    max-width: 100%; /* Ensures the image does not exceed the container width */
    height: auto; /* Maintains the aspect ratio */
    width: auto; /* Resets any previously set width */
    padding:20px;
    overflow: hidden;
}
.blog-item p{
  margin: 20px auto;
  text-align: left;
}
.blog-item h1{
  margin: 20px auto;
}


.blog-read-container .blog-item img {
    max-width: 100%; /* Ensures the image does not exceed the container width */
    height: 300px; /* Sets a fixed height of 100px for the images */
    width: 550px; /* Maintains the aspect ratio */
    padding: 10px;
    overflow: hidden;
}



.blog-read-section .blog-suggested{
  max-width: 1100px;
  min-width: 350px;
  width: 100%;
  margin : 0 auto;
}

/* bootcamp in blog css */
.blog-read-container .bootcamp-card{
  max-width: 400px;
  min-width: 400px;
  width: 35%;
}

@media screen and (max-width: 990px) {
  .blog-read-container .bootcamp-card{
    display: none;
  }

}

/* Mobile */
@media screen and (max-width: 500px) {
  .login-essonova {
    width: 85%;

  }

}

/*
*******************************************
_______BLOG READER  CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/

/*
*******************************************
_______CONTACT CSS START________
*******************************************
*/
.contact-essonova .contact-form {
  max-width: 960px;
  margin: 0 auto;
  padding: 3.125rem;
  border-radius: 1rem;
  background: var(--white);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.07);
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

/* CSS for Contact Form Animation */

/* CSS for Contact Form Animation and Styling */

/* Initial state of the contact form elements */
.contact-form {
  opacity: 0;
  transform: translateY(20px);
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-form h3,
.contact-form .form-group,
.contact-form .btn-theme {
  opacity: 0;
  transform: translateY(20px);
  margin-bottom: 15px;
}

/* Animation for fading in elements */
.contact-form.wow.fadeInUp {
  animation: fadeInUp 0.8s ease forwards;
}

.contact-form.wow.fadeInUp .form-group,
.contact-form.wow.fadeInUp .btn-theme {
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.2s;
}

/* Keyframes for fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Animation for fading in elements */
.contact-form.wow.fadeInUp .form-group {
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.2s;
}

.contact-form.wow.fadeInUp h3,
.contact-form.wow.fadeInUp .btn-theme {
  animation: fadeInUp 0.8s ease forwards;
}

/* Keyframes for fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}



/* Center the content vertically and horizontally */
.get-started {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

}

/* Style the map container */
.map {
  padding: 20px;
}

/* Make the map responsive */
.embed-responsive {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  /* 1:1 Aspect Ratio (for a square map) */
}

.embed-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*
*******************************************
_______CONTACT CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______HEADER CSS START________
*******************************************
*/
/*
*******************************************
_______HEADER CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______LOGIN CSS START________
*******************************************
*/

.login-essonova {
  position: absolute;
  top: 10%;
  /* right: 30%; */
  margin: 0 auto;
  /* Center the form horizontally */
  left: 0;
  /* Reset the left property */
  right: 0;
  /* Reset the right property */
  background: rgb(206, 237, 235);
  z-index: 10;
  width: 600px;
  border-radius: 10px;

}

/* ----------close login button css ------------- */

.login-close-div {
  text-align: right;
  margin-right: 10px;
  margin-top:  8px;
}

.login-close-div button {
  text-align: center;
  background: rgb(206, 237, 235);
  font-size: 1.5em;
  border: none;
}

/* ------------------------------------------------- */

.form-group form-control,
h2 {
  font-size: 15px;
}

.login-essonova .login-form {
  max-width: 960px;
  margin: 0 auto;
  padding: 3.125rem;
  border-radius: 10px;
}

.login-form {
  opacity: 0.9;
  transform: translateY(20px);
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}


.login-form h3,
.login-form .form-group,
.login-form .btn-theme {
  opacity: 0;
  transform: translateY(20px);
  margin-bottom: 15px;

}

/* Animation for fading in elements */
.login-form.wow.fadeInUp {
  animation: fadeInUp 0.8s ease forwards;
}

.login-form.wow.fadeInUp .form-group,
.login-form.wow.fadeInUp .btn-theme {
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.2s;
}

.login-essonova .container,
.section-title text-center wow fadeInDown .section-tag fw-bold {
  margin-top: 40px;
  font-size: x-large;
}

.form-control {
  margin-top: 10px;
}

.login-essonova .container .dropdown_btn {
  border: ridge;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  margin-top: 8px;
  margin-left: -2px;
}

/* Keyframes for fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  /* Adjust the right offset as needed */
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

/* Animation for fading in elements */
.login-form.wow.fadeInUp .form-group {
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.2s;
}

.login-form.wow.fadeInUp h3,
.login-form.wow.fadeInUp .btn-theme {
  animation: fadeInUp 0.8s ease forwards;
}

.container_login--checkobox-and-forget-password {
  display: flex;
}

.container_login--check-box {
  width: 70%;
}

.container_login--forget-password {
  text-align: right;
  cursor: pointer;

}

/* Keyframes for fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}



@media screen and (max-width: 990px) {
  .login-essonova {
    width: 75%;

  }
}

/* Mobile */
@media screen and (max-width: 500px) {
  .login-essonova {
    width: 85%;

  }

}





/*
*******************************************
_______LOGIN CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______REGISTRATION CSS START________
*******************************************
*/

.register-essonova {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: rgb(206, 237, 235);
  z-index: 10;
  width: 600px;
  border-radius: 10px;

}

.register-button-div {
  text-align: right;
  margin-right: 10px;
  margin-top:  8px;
  
}

.register-button-div button {
  text-align: center;
  background: #ffffff;
  font-size: 1.5em;
  border: none;
  background: rgb(206, 237, 235);
}


.register-essonova .register-form {
  max-width: 960px;
  margin: 0 auto;
  padding: 3.125rem;
  border-radius: 1rem;
  
}

/* Initial state of the register form elements */
.register-form {
  opacity: 0.9;
  transform: translateY(20px);
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.register-form h3,
.register-form .form-group,
.register-form .btn-theme {
  opacity: 0;
  transform: translateY(20px);
  margin-bottom: 15px;
}

/* Animation for fading in elements */
.register-form.wow.fadeInUp {
  animation: fadeInUp 0.8s ease forwards;
}

.register-form.wow.fadeInUp .form-group,
.register-form.wow.fadeInUp .btn-theme {
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.2s;
}



.form-control {
  margin-top: 10px;
}

.register-essonova .container-register .dropdown_btn {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 11px;
    width: 100%;
    margin-top: 10px;
    margin-left: -2px;
}

li {
  list-style: none;
}

/* Keyframes for fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Animation for fading in elements */
.register-form.wow.fadeInUp .form-group {
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.2s;
}

.register-form.wow.fadeInUp h3,
.register-form.wow.fadeInUp .btn-theme {
  animation: fadeInUp 0.8s ease forwards;
}

/* Keyframes for fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}



@media screen and (max-width: 990px) {
  .register-essonova {
    width: 75%;

  }
}

/* Mobile */
@media screen and (max-width: 500px) {
  .register-essonova {
    width: 85%;

  }

}





/*
*******************************************
_______REGISTRATION CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______FORGET_PASSWORD CSS START________
*******************************************
*/
.forget_password-essonova {
  position: absolute;
  top: 10%;
  margin: 0 auto;
  left: 0;
  right: 0;
  background: rgb(206, 237, 235);
  z-index: 10;
  width: 600px;
  border-radius: 10px;
}

/* ----------close forgetpassword button css ------------- */

.forget_password-close-div {
  text-align: right;
  margin-right:  10px;
  margin-top:  8px;
}

.forget_password-close-div button {
  text-align: center;
  background: rgb(206, 237, 235);
  font-size: 1.5em;
  border: none;
}

/* ------------------------------------------------- */

.form-group form-control,
h2 {
  font-size: 15px;
}

.forget_password-essonova .forget_password-form {
  max-width: 960px;
  margin: 0 auto;
  padding: 3.125rem;
  border-radius: 1rem;

}

.forget_password-form {
  opacity: 0.9;
  transform: translateY(20px);
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}


.forget_password-form h3,
.forget_password-form .form-group,
.forget_password-form .btn-theme {
  opacity: 0;
  transform: translateY(20px);
  margin-bottom: 15px;

}

/* Animation for fading in elements */
.forget_password-form.wow.fadeInUp {
  animation: fadeInUp 0.8s ease forwards;
}

.forget_password-form.wow.fadeInUp .form-group,
.forget_password-form.wow.fadeInUp .btn-theme {
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.2s;
}

.forget_password-essonova .container,
.section-title text-center wow fadeInDown .section-tag fw-bold {
  margin-top: 40px;
  font-size: x-large;
}

.form-control {
  margin-top: 10px;
}

.forget_password-essonova .container .dropdown_btn {
  border: ridge;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  margin-top: 8px;
  margin-left: -2px;
}

/* Keyframes for fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Animation for fading in elements */
.forget_password-form.wow.fadeInUp .form-group {
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.2s;
}

.forget_password-form.wow.fadeInUp h3,
.forget_password-form.wow.fadeInUp .btn-theme {
  animation: fadeInUp 0.8s ease forwards;
}

/* Keyframes for fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}



@media screen and (max-width: 990px) {
  .forget_password-essonova {
    width: 75%;

  }
}

/* Mobile */
@media screen and (max-width: 500px) {
  .forget_password-essonova {
    width: 85%;

  }

}
/*
*******************************************
_______FORGET_PASSWORD CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/


/*
*******************************************
_______FOOTER CSS START________
*******************************************
*/
.neuratechlab__footer {
  padding: 2rem 0;
  color:darkblue;
  background: rgb(206, 237, 235);
}

/* Style the heading */
.neuratechlab__footer-heading h1 {
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.neuratechlab__footer-heading h1:hover {
  transition: color 0.3s ease;
  transform: translateY(-2px);
  /* Add a subtle upward lift on hover */
}

/* Style for the content container */
.content {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
  align-items: center;
  padding: 1.75rem;
  border-radius: 1rem;
  box-shadow: #b1d1da;
  text-align: center;
  margin: 20px auto;
  width: 800px;
  /* Move text-align here if it's meant for the content container */
}

/* Style for the subscription form */
.subscribe-form {
  /* flex-direction: column; */
  align-items: center;
  transition: transform 0.3s ease;
}

.email-input {
  padding: 0.5rem;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  outline: none;
 
}



.btn-theme {
  padding: 0.5rem 1rem;
  background-color: #21AEA7;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Added transform for a slight scaling effect */
}

.btn-theme:hover {
  background-color: #C4E1D7;
  transform: scale(1.05);
  /* Scale the button slightly on hover */
}


/* Add styles for form error state */
.error {
  border-color: red;
}


/* Style the footer links and logo */
.neuratechlab__footer-links_logo img {
  height: 180px;
  max-width: 100%;
  /* margin-bottom: 1rem; */
}

.neuratechlab__social-icons {
  display: flex;
  gap: 10px;
  /* Adjust the gap between icons */
}

.social-icon {
  font-size: 24px;
  /* Adjust the icon size */
  color: #555;
  /* Adjust the icon color */
  text-decoration: none;
  /* Remove underline on hover */
  transition: color 0.3s ease;
  /* Smooth color transition on hover */

  &:hover {
    color: #21AEA7;
    /* Change color on hover */
  }
}

/* Style the columns for links and contact information */
.neuratechlab__footer-links_div h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.neuratechlab__footer-links_div p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

/* Style the copyright text */
.neuratechlab__footer-copyright {
  text-align: center;
  margin-top: 2rem;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .neuratechlab__footer-heading h1 {
    font-size: 20px;
  }

  .email-input {
    width: 100%;
  }

  .neuratechlab__footer-links_div {
    margin-bottom: 2rem;
  }
}

/*
*******************************************
_______FOOTER CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/

/*__________________________________________________________________________________________________________________*/
/*__________________________________________________________________________________________________________________*/

/*_____________________________PROGRAM COMPONENTS CSS______________________________________________________________*/
/*__________________________________________________________________________________________________________________*/


/* 
*******************************************
_______BACKEND CSS START________
******************************************* */
.backend-container {
  height: 400px;
  width: 100%;
}

.Images_Backend {
  margin-left: 28%;
  width: 40%;
  height: 20%;
}

/* *******************************************
_______BACKEND CSS END________
******************************************* */

/*__________________________________________________________________________________________________________________*/


/* *******************************************
_______Frontend CSS START________
******************************************* */

.container-frontend {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header Styles */
.App {
  text-align: center;
  margin-bottom: 40px;
}

.App h1 {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.App h1:hover {
  color: #007bff;
  transform: scale(1.05);
}

.App p {
  font-size: 18px;
  color: #555;
}

/* Program Schedule  Section Styles */

.roadmap-card {
  flex: 1;
  height: 400px;
  padding: 20px;
  border: 1px solid #21AEA7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(21, 212, 209, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
  background: rgb(21, 117, 111);
  background: linear-gradient(328deg, rgba(206, 237, 235, 1) 41%, rgba(167, 223, 220, 1) 83%);
}


.roadmap-card h3 {
  font-size: 16px;
  /* Adjusted font size for better readability */
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}

.roadmap-card ul {
  list-style-type: disc;
  padding-left: 20px;
}


/* Technology Section Styles */


.technology-card {
  flex: 1;
  height: 420px;
  background-color: #f8f8f8;
  border: 1px solid #21AEA7;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  overflow: hidden;
}


.technology-card img {
  max-width: 100%;
  height: auto;
  width: 150px;
  /* Set the desired width for your image */
  height: 150px;
  /* Set the desired height for your image */
  margin-bottom: 15px;
  border-radius: 5px;
}


.technology-card h4 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}

.technology-card p {
  font-size: 16px;
  margin: 0;
  color: #666;
}

/* Container styles */
.container-schedule {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
}

.colored-icon {
  color: #21AEA7;
  /* Set the desired icon color here */
}

/* Schedule and Needs list styles */
.schedule-list,
.needs-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Schedule item styles */
.schedule-item,
.needs-item {
  flex: 0 0 calc(33.3333% - 20px);
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

/* Hover effect */
.schedule-item:hover,
.needs-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
}

/* Schedule and Needs item icon styles */
.schedule-item i,
.needs-item i {
  font-size: 32px;
  margin-right: 10px;
  color: #007bff;
}

/* Schedule and Needs item list styles */
.schedule-item ul,
.needs-item p {
  list-style: none;
  margin: 0;
  padding: 0;
}

.schedule-item ul li,
.needs-item p {
  margin-bottom: 10px;
  color: #777;
  font-size: 0.9rem;
}

/* Center the text in the items */
.schedule-item h3,
.needs-item h3,
.schedule-item ul li,
.needs-item p {
  text-align: center;
}

/* Center the icons in the items */
.schedule-item i,
.needs-item i {
  display: block;
  margin: 0 auto;
}



/* Media query for smaller screens */
@media (max-width: 768px) {

  .schedule-item,
  .needs-item {
    flex: 0 0 calc(50% - 20px);
  }
}

/* Prerequisites Section Styles */
.prerequisites-section h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* .prerequisites-section ul {
    list-style-type: disc;
    padding-left: 20px;
  } */

.prerequisites+--section li {
  font-size: 16px;
}

/* Hover Effects for Lists */
ul:hover li {
  cursor: pointer;
  transition: color 0.3s ease;
}

/* Syllabus.css */


/* Form Control */
.form-control {
  margin-bottom: 10px;
}

/* Checkbox Label */
.term_condition {
  font-size: 14px;
}

/* Download Button */
.download-button {
  margin-top: 20px;
}

/* Image */
.syllabus-image {
  max-width: 100%;
  height: auto;
}

/* Responsive Padding */
@media (max-width: 767px) {
  .syllabus-container {
    padding: 20px;
  }
}


/* terms and condition css */
/* Basic fade-in animation */
.container.terms-container .privacy-container {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* *******************************************
_______Frontend CSS END________
******************************************* */

/*__________________________________________________________________________________________________________________*/

/* 
*******************************************
_______Basic CSS START________
******************************************* */
.Image_basic {
  margin-left: 28%;
  width: 40%;
  height: 20%;
}


/* 
*******************************************
_______Basic CSS END________
******************************************* */

/*__________________________________________________________________________________________________________________*/

/* 
*******************************************
_______FullStack CSS START________
******************************************* */
.Image_FullStack {
  margin-left: 28%;
  width: 40%;
  height: 20%;

}


/* 
*******************************************
_______Basic CSS END________
******************************************* */

/* 
*******************************************
_______COURSE CARD CSS START________
******************************************* */


/* 
*******************************************
_______COURSE CARD CSS END________
******************************************* */


/*
*******************************************
_______COURSE Slider CSS START________
*******************************************
*/
/* Styles for the container */
.essonova-course-slider-container {
  padding: 20px;
  text-align: center;
  max-width: 1300px;
  margin:  0 auto;
}

/* Styles for the slider container */
.essonova-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

/* Styles for left and right navigation icons */
.slider-icon-contianer-left,
.slider-icon-container-right {
  font-size: 18px;
  width: 40px;
  color: #fff;
  cursor: pointer;
  background-color: #21AEA7;
  border-radius: 10%;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.slider-icon-contianer-left:hover,
.slider-icon-container-right:hover {
  background-color: #3F1179;
  color: #fff;
}

/* Styles for the card container */
.slider-card-container {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.3s ease;
}

/* Styles for individual cards */
.essonova-course-card {
  position: relative;
  flex: 0 0 calc(33.33% - 20px);
  margin: 0 10px;
  padding: 10px;
  border: 1px solid #21AEA7;
  text-align: center;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

/* Styles for card images */
.card-img img {
  width: 200px; /* Set your desired fixed width */
  height: 150px; /* Set your desired fixed height */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}

/* Styles for card descriptions */
.essonova-course-card p {
  font-size: 16px;
  color: #666;
  margin: 10px 0;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .slider-card-container {
    flex-wrap: wrap;
  }
  .essonova-course-card {
    flex: 0 0 calc(50% - 20px);
  }
}
/* Styles for the "Explore ME!" button */
.essonova-course-card div {
  margin-top: 10px; /* Adjust the spacing between the description and the button */
  text-align: center;
}

.essonova-course-card button.essonova_btn {
  background-color: #21AEA7; /* Set your desired button background color */
  color: #fff; /* Set your desired button text color */
  padding: 10px 20px; /* Set your desired button padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.essonova-course-card button.essonova_btn:hover {
  background-color: #3F1179; /* Set the hover background color */
}
@media (max-width: 750px) {
  .slider-card-container {
    flex-wrap: wrap;
  }
  .essonova-course-card {
    flex: 0 0 calc(100% - 20px);
  }
}



/*
*******************************************
_______Slider CSS END________
*******************************************
*/
/*__________________________________________________________________________________________________________________*/







/*
*******************************************
_______User Profile CSS ________
*******************************************
*/

.user-profile-essonova {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: rgb(206, 237, 235);
  z-index: 10;
  width: 600px;

}

.user-profile-button-div {
  text-align: right;
  margin-right: 20px;
  
}

.user-profile-button-div button {
  text-align: center;
  background: #ffffff;
  font-size: 1.5em;
  border: none;
  background: rgb(206, 237, 235);
}


.user-profile-essonova .user-profile-form {
  max-width: 960px;
  margin: 0 auto;
  padding: 3.125rem;
  border-radius: 1rem;
  
}

/* Initial state of the user-profile form elements */
.user-profile-form {
  opacity: 0.9;
  transform: translateY(20px);
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-profile-form h3,
.user-profile-form .form-group,
.user-profile-form .btn-theme {
  opacity: 0;
  transform: translateY(20px);
  margin-bottom: 15px;
}

/* Animation for fading in elements */
.user-profile-form.wow.fadeInUp {
  animation: fadeInUp 0.8s ease forwards;
}

.user-profile-form.wow.fadeInUp .form-group,
.user-profile-form.wow.fadeInUp .btn-theme {
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.2s;
}



.form-control {
  margin-top: 10px;
}

.user-profile-essonova .container-user-profile .dropdown_btn {
  border: ridge;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  margin-top: 8px;
  margin-left: -2px;
}

li {
  list-style: none;
}

/* Keyframes for fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Animation for fading in elements */
.user-profile-form.wow.fadeInUp .form-group {
  animation: fadeInUp 0.8s ease forwards;
  animation-delay: 0.2s;
}

.user-profile-form.wow.fadeInUp h3,
.user-profile-form.wow.fadeInUp .btn-theme {
  animation: fadeInUp 0.8s ease forwards;
}

/* Keyframes for fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}



@media screen and (max-width: 990px) {
  .user-profile-essonova {
    width: 75%;

  }
}

/* Mobile */
@media screen and (max-width: 500px) {
  .user-profile-essonova {
    width: 85%;

  }

}

/*
*******************************************
_______User Profile CSS END ________
*******************************************************************************************************************
*/



/*
*******************************************
_______Alert Message Butoon CSS ________
*******************************************
*/

.alert-message{
  padding: 10px 20px;
  margin: 0 auto;
  font-size: medium;
}

/*
*******************************************
_______Alert Message CSS END ________
*******************************************************************************************************************
*/



/*
*******************************************
_______Essonova Butoon CSS ________
*******************************************
*/


/* button syle css class */

.essonova-btn{
  background: #21AEA7;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: none;
  margin: 0 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  border: none;
}

.essonova-btn:hover {
  background: #3F1179;
}

.essonova-btn-rev {
  background: #3F1179;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  text-decoration: none;
  margin: 0 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  border: none;
}

.essonova-btn-rev:hover {
  background: #21AEA7;
}


/***** Loader *****/

.essonova-loader{
display: flex;
align-items: center;
justify-content: center;
}

/***** End Loader *****/
/*
*******************************************
_______COMMON CSS END________
*******************************************
*/

