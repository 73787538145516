.edit-info-container {
  position: absolute;
  z-index: 10;
  left: 20%;
  right: 0;
  margin: 0 auto;
  top: 30%;
  background: #ffffff;
  padding: 0px 0px;
  width: 500px;
  height: 480px;
  border: 2px solid black
}

.edit-info-container table td {
  text-align: center;
  border-bottom: none;
}

.edit-info-container table th {
  text-align: center;
  border-bottom: none;
}

.edit-info-container__table__td__input {
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;

}


.edit-info-container__table__submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  background: #22d9a3;
  padding: 10px 10px;
  border-radius: 10px;
  width: 90px;
  margin: 0 auto;
}

.edit-info-container__table__cancel-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  background: #dc7359;
  padding: 10px 10px;
  border-radius: 10px;
  width: 90px;
  margin: 0 auto;
}

@media screen and (max-width: 1050px) {
  .edit-info-container {
    left: 0;

  }
}
@media screen and (max-width: 750px) {
  .edit-info-container {
    width: 95%;
    height: 480px;

  }
}