.delet-user-conainer{
    position: absolute;
    z-index: 10;
    top: 30%;
    left : 20%;
    right: 0;
    margin: 0 auto;
    background: #ffff;
    padding: 15px 20px 15px 20px;
    width: 350px;
    height: 180px;
    display: flex;
    flex-direction: column;
    border-radius: 20PX;
    border : 2px solid black

}
.delete-user-container__message{
    color: red;
    padding: 5px 20px 25px 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
}

.delet-user-conainer__event{
    background: #ffff;
    display: flex;
    gap : 50px;


}

.delet-user-conainer__event__confirm{
    padding: 5px 10px;
    border-radius: 10px;
    color : #ffff;
    background : #22d9a3;
    cursor: pointer;
    width: 50%;
    text-align: center;
}

.delet-user-conainer__event__cancel{
    padding: 5px 10px;
    border-radius: 10px;
    color : #ffff;
    background : #dc7359;
    cursor: pointer;
    width: 50%;
    text-align: center;
}

/* .delet-user-conainer__event__confirm:hover{
    background : #22d9a3;
} */

@media screen and (max-width: 1050px) {
    .delet-user-conainer{
      left: 0;
  }
}