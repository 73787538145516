@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
    --font-family: 'Lora', serif;
    --gradient-text: linear-gradient(89.97deg, #21AEA7 1.84%, #3F1179 69.67%);
    --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
    --color-bg: #040C18;
    --color-footer: #031B34;
    --color-blog: #042c54;
    --color-text: #81AFDD;
    --color-subtext: #FF8A71;
}

/* body {
  background: rgb(18,70,149);
  background: linear-gradient(0deg, rgba(18,70,149,0.7786764363948704) 0%, rgba(216,224,230,0.1848389013808649) 6%, rgba(207,222,227,0.1988445036217612) 95%, rgba(102,209,210,0.6302170526413691) 100%);
} */