/* .admin-usermanage-container{
    width: 100%;
}

th.admin-usermanage-container__table__th__id{
    width: 5%;

}
th.admin-usermanage-container__table__th__name{
    width: 20%;
}
th.admin-usermanage-container__table__th__email{
    width: 25%;
}
th.admin-usermanage-container__table__th__username{
    width: 10%;
}
th.admin-usermanage-container__table__th__phone{
    width: 10%;
}
th.admin-usermanage-container__table__th__course-enrolled{
    width: 25%;
}
th.admin-usermanage-container__table__th__new-user{
    width: 5%;
}
th.admin-usermanage-container__table__th__search{
    width: 10%;
} */

.admin-usermanage-container__page-numbers{
    display: flex;
    width : 300px;
    margin:  0 auto;

}
.prev-page-button,.current-page-number,.next-page-button{
    flex-grow: 1;
    text-align: center;
}

.prev-page-button,.next-page-button{
    background-color: rgb(119, 217, 119);
    color: white;
    cursor: pointer;
}


@media screen and (max-width: 1050px) {
    .admin-usermanage-container__table{
        margin-top: 25px;
        }
  }


