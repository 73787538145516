.admin-blogs-manage-container{
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 1050px) {
    .admin-blogs-manage-container__table{
        margin-top: 25px;
        }
  }

