.admin-panel-container {
    display: flex;
    height: 630px;
    width: 100%;
    overflow: hidden;
}


.admin-panel-container__sidebar {
    flex: 1;
    background-color:rgb(95, 196, 172);
    color: white;
    padding: 20px;
    min-width: 200px;
    width: 100%;
    min-height: 500px;
}


.admin-panel-container__sidebar li{
    list-style: none;
    font-size: 23px;
    padding: 20px;
    margin : 10px auto;
    cursor: pointer;
}

.admin-panel-container__content-area {
    flex: 4;
    background-color: #ece4e4;
    width: 100%;
}

.admin-panel-container__content-area {
  overflow:auto;
}

/* Style the table */
.admin-panel-container__content-area table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .admin-panel-container__content-area td{
    padding: 10px 10px 0 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .admin-panel-container__content-area  th {
    padding : 10px;
    background-color: #333;
    color: white;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  
  .admin-panel-container__content-area button {
    padding: 5px 10px;
    color: white;
    border: none;
    cursor: pointer;
  }
  .admin-panel-container__content-area .edit-button button {
    background-color: #007bff;
  }
  .admin-panel-container__content-area .delete-button button {
    background-color: #e3034a;
  }

  .admin-panel-container__sidebar__icon{
    margin-right: 15px;
  }

  .admin-panel-container__sidebar__heading{
    text-align: center;
    font-size: 30px;
  }
 
  hr.admin-panel-container-divider{
    border-top: 4px solid black;
  }

  .admin-panel-container__content-area__top-bar{
    display: flex;
    text-align: right;
  }


/* ***************************************** */
/* toggle menu side bar */
/* ***************************************** */



.admin-panel-container__sidebar__small-menu{
  position: absolute;
  z-index: 40;
  display: none;
  background: white;
  width: 100%;
}
.admin-panel-container__sidebar__small-menu__items{
  background-color:rgb(95, 196, 172);
  color: white;
  padding: 10px;
  min-width: 200px;
  width: 300px;
  min-height: 400px;
  max-height: 500px;
  position: absolute;
  z-index: 10;
  top: 100%;
}

.admin-panel-container__sidebar__small-menu li{
  list-style: none;
  font-size: 23px;
  padding: 5px 20px;
  margin : 10px auto;
  cursor: pointer;
}
/* ***************************************** */
/* toggle menu side bar */
/* ***************************************** */



@media screen and (max-width: 1050px) {
  .admin-panel-container {
    flex-direction: column;
}
  .admin-panel-container__sidebar{
    display: none;
  }
  .admin-panel-container__sidebar__small-menu{
    display: flex;
  }
  
}

@media screen and (max-width: 700px) {
  

 
}

@media screen and (max-width: 550px) {
  
}
