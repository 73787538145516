.admin-add-blog-container {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #ffffff;
  padding: 0px 0px;
  height: 800px;
  width: 100%;
  border: 2px solid black;
  overflow: auto;
  display: flex;
  
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: 6px; /* Firefox */

}
.admin-add-blog-container::-webkit-scrollbar {
  width: 6px;
}
.admin-add-blog-container__blog-content::-webkit-scrollbar {
  width: 6px;
}


.admin-add-blog-container__left-panel {
  display: flex;
  align-items: center;
  justify-content:left;
  flex-direction: column;
  width: 100%;
  max-width: 750px;
  margin: 10px auto;
}

.admin-add-blog-container__left-panel input {
 
}

.admin-add-blog-container__blog-content {
  width: 100%;
  height: 350px;
  min-height: 200px;
  margin: 40px 40px;
  margin-bottom: 40px;
  overflow: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.admin-add-blog-container__blog-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.admin-add-blog-container__blog-content {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}



.admin-add-blog-container__right-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 300px;
  flex-direction: column;
}

.editor {
  width: 650px;
  height: 450px;
}

.admin-add-blog-container__image img {
  height: 550px;
  width: 650px;
}

.admin-add-blog-container input,
.admin-add-blog-container select {
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 14px;
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  max-width: 650px;
  min-width: 350px;
  height: 40px;
  margin: 10px auto;
}


.admin-add-blog-container__submit-button,
.admin-add-blog-container__cancel-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 90px;
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 10px;
  margin: 30px auto;
}

.admin-add-blog-container__submit-button {
  background: #22d9a3;
}

.admin-add-blog-container__cancel-button {
  background: #dc7359;
}


@media screen and (max-width: 1050px) {
  .admin-add-blog-container {
    height: 750px;
    flex-direction: column;
  }
  .admin-add-blog-container__right-panel {
    flex-direction:row;
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .blog-date-and-categor {
    flex-direction: column;
  }
  
}


@media screen and (max-width: 450px) {
  .blog-date-and-categor {
    flex-direction: column;
  }
  .admin-add-blog-container__blog-content {
    
    margin: 20px  auto;
    margin-bottom: 10px;
    
  }
  .admin-add-blog-container__left-panel input {
    height: 35px;
    margin: 40 auto;
  }
}
